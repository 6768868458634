#emailVerificationContent{
    background-color: white;
    height: 287px;
    width: 386px;
    border-radius: 12px;
    margin: auto
}
#header{
    height: 44px;
    left: 24px;
    top: 19px;
    margin-left: 24px;
    padding-top: 20px;
    margin-bottom: 30px;
}
#headerText{
    color: #0D2048;
    font-family: jaf-bernina-sans-comp, serif;
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
}
#conformation{
    margin: 50px 24px 24px;
    font-family: Proxima Nova, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
#belowBox{
    font-family: Proxima Nova, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
}
.divider{
    position: relative;
    border-top: 2px solid #8c8b8b;
}
#buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    vertical-align: middle;
    margin-top: 30px;
}
#backButton{
    width: 160px;
    height: 40px;
    background: transparent;
    border-color: #E6E9ED;
    border-width: 2px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    margin-left: 24px;

}
#nextButton{
    width: 160px;
    height: 40px;
    border-width: 2px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
}
