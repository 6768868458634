@import "../gaigColors";

#createPasswordPage {
  #password, #togglePasswordView {
    outline: none;
    border: none;
    box-shadow: none;
  }

  #passwordInputGroup {
    border: 1px solid $navy6;
    .input-group-text {
      background-color: transparent;
      border: none;
    }
  }

  label {
    font-family: sans-serif;
    font-size: x-small;
  }

  .gaig-panel {
    width: 25rem;
  }

  .form-text {
    font-size: small;
  }

  .rule {
    margin-bottom: 0.5em;
    > .rule-icon {
      font-size: larger;
    }
  }

}
.input-group.error {
  > input {
    // need !important here because we make the ui look a bit different than bootstrap intended, and it'd be a pain to override specificity rules
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
    &:focus {
      box-shadow: none;
    }
  }
  > span, > input {
    border-color: $failure;
  }
}
