@import "../gaigColors";

#errorBox {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  width: 40%;
}

.gaig-panel {
  margin: auto;
  width: 100%;
}

#errorBoxTitle {
  margin: 1%;
  text-align: left;
  font-size: 3em;
}

#error-text {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2em;
  color: #00274E;
}

#resetTokenLink {
  width: 50%;
  margin: auto;
  padding: 1%;
}
