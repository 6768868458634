@import '../gaigColors';

#loadingPage {
  > .gaig-panel > .row {
    > * {
      display: flex;
      justify-content: center;
      color: $navy;
      margin-bottom: 16px;
    }
    > h2 {
      font-size: 1em;
    }
  }

  .fa-spin {
    font-size: 3em;
  }
}