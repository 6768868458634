hr {
  &::after {
    width: unset;
  }
}

#submitReset {
  width: 100%;
  margin-top: 1em;
}

.gaig-panel > h1 {
  margin-bottom: 0.5em;
}
