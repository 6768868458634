@import "../gaigColors";

.gaig-panel {
  padding: 32px;
  width: 35rem;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0 4px 20px 0 #0033660d;

  h1 {
    font-family: jaf-bernina-sans-comp, sans-serif;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 10%;
    line-height: 110%;
    color: $navy;
    text-transform: none;
  }

  .center {
    text-align: center;
  }

  span, p {
    color: $navy
  }
}
