@import "../../../assets/sass/gaigColors";

#panel {
  hr {
    margin: 2em -2em 2em -2em;
    border: 1px solid var(--bs-gray-800);

    &:after {
      display: none;
    }
  }

  .gaig-panel {
    margin: auto;
    width: 100%;
  }

  h1.panel-header {
    padding-bottom: 0;
    margin-bottom: 0;
    &.center {
      text-align: center;
    }
  }

  .btn {
    &.enabled {
      background-color: $navy;

      p {
        color: white;
      }
    }

    &.disabled {
      background: $navy8;
      border-radius: 4px;
      color: $navy6;
    }
  }

  .form-text.error-text {
    color: $failure;
  }

  .form-tip {
    &:not(.error-text) {
      color: $navy;
    }

    line-height: 16px;
  }

  .input-group > .form-control {
    border-left: none;
  }

  .form-label {
    font-size: .875em;
    margin-bottom: 0;
  }

  .input-group-text {
    background-color: transparent;
    border-right: none;
    padding-right: 2px;
    color: $navy6;
  }

  #nextBtn {
    width: 50%;
    margin-bottom: 1em;
  }

  .progress {
    margin: 0 6em 0 6em;
    height: 3px;
    background-color: $navy5;
  }

  .progress-bar {
    background-color: $navy8;
  }

  .checkpoints {
    margin: -17px 3.6em 3em 3.6em;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    color: $navy8;

    .checkpointTitle {
      text-transform: uppercase;
      margin: 2.5em 0 0 -2.5em;
      font-size: 11px;
      font-weight: 600;
      width: 9em;
      text-align: center;
    }
  }

  #emailConfirmed, #linkExpired, #resentLink, #finishingRegistration, #registrationFailure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  h1 {
    margin-bottom: 8px;
  }

  .password-text {
    font-size: 11px;
    padding-top: 16px;
  }

  .password-rule-text {
    margin-left: 1em;
    font-size: 14px;
    padding-top: .1em;
    position: absolute;
  }

  .text-rule {
    padding-top: 8px;
  }

  .tooltip-footer {
    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    padding-top: 16px;
    color: #00274E;
  }

  b.required {
    color: $failure;
  }
}
