.agent-labels{
    color: #036;
    font-size: .6em;
}

.signInHeader{
  font-weight:bold;
  font-size: 2.2em;
  font-family:Impact;
}

.group-panels{
  justify-content: center;
  align-items: center;
  position: relative;
}

.panel-signin, .panel-signup {
  display: flex !important;
  flex-direction: column;
}

.panel-signin {
  padding-left: 32px;
  padding-right: 32px;
  min-height: 32em;
  width: 26em;
  min-width: 25em;
  margin-left: 25%;
  /*  left: 0px;
    top: 0px;
   */
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #0033660D;
  /*
    z-index: 99;
    right: 400px;
   */
}

.panel-signup{
  background: #E6E9ED;
  height: 24em;
  width: 392px;
  border-radius: 12px;
  padding: 20px 24px 24px;
  margin-right: 25%;
  margin-top: 4em;
}

.headerRequestAccess{
  font-family:Impact;
  font-size: 1.5em;
  padding-top:10%;
  margin-bottom: 10%;
  line-height: 110%;
  color: #003366;
  text-transform: none;
}
#accessagreement{
    margin:auto;
    text-align: center;
}

.headerRequestAccess{
  font-family: jaf-bernina-sans-comp, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  padding-top:10%;
  margin-bottom: 10%;
  line-height: 110%;
  color: #003366;
  text-transform: none;
}

.bodyRequestAccess{
  line-height: 1.428571429;
  color: #5f738e;
}

.agentSignIn{
  font-weight:bold;
  font-size: 2.2em;
  font-family:Impact;
}