@import "../gaigColors";
// list of button colors to generate rules from
$buttonColors: (
  "primary": $navy,
  "success": $success,
  "failure": $failure,
  "warning": $warning
);


.ga_Button {
  padding: 8px 24px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 600;

  &.link {
    height: fit-content;
    width: fit-content;
    padding: 0;
    background-color: transparent;
    color: $navy;
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    text-decoration: underline;
    margin-left: 4px;
  }

  @each $name, $color in $buttonColors {
    &.ga_Button-#{$name} {
      background-color: $color
    }

    &.ga_Button-#{$name}-outline {
      border-color: $color;
      color: $color;
      background-color: transparent;
      &:hover {
        color: white;
        background-color: $color;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: $navy8;
    color: $navy5;
  }
}
