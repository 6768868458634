.WelcomeModal{
    margin: auto;
    width: 660px;
    height: 587px;
    left: 390px;
    top: 191px;
    background: #FFFFFF;
    border-radius: 12px;
}

.WelcomeModalHeader{
    margin: 30px auto;
    height: 100.61px;
    left: 390px;
    right: 390px;
    top: 207.26px;
    text-align: center;
    background: #FFFFFF;
    border-radius: 12px 12px 0px 0px;
}
.WelcomeTitle{
    font-family: Bernina Sans, serif;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #00274E;
    background: #FFFFFF;
}
.WelcomeImage{
    margin: 30px auto;
    height: 74px;
    width: 86px;
}
#WelcomeTextArea{
    margin: auto;
    width: 520px;
    background: #FFFFFF;
    left: calc(50% - 520px/2);
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #00274E;
}
.Option{
    background: #F4F5F7;
    border-radius: 4px;
    width: 612px;
    height: 68px;
    left: 0px;
    top: 0px;
    margin: 30px auto;
}
.LeftContainer{
    width: 60%;
    text-align: left;
    margin-top: 5px;
}
.RightContainer{
    width: 40%;
    float: left;
    padding: 20px;
    text-align: right;
}

.ImageCell{
    position: relative;
    width: 20%;
    float: left;
    height: 100%;
    padding-left: 30px;
    padding-top: 20px;

}
.TextCell{
    width: 80%;
    float: left;
    height: 100%;
    padding-top: 18px;
    font-family: Proxima Nova, serif;
    font-size: 14px;
    line-height: 130%;
    color: #00274E;
}

#resetPasswordLink {
    font-size: 13px;
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: arial, sans-serif;
    cursor: pointer;
    text-decoration:none;
}






