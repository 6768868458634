@import "../gaigColors";

#accessAgreement {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .access-modal-dialog {
    width: 100%;
    background-color: white;
    z-index: inherit;
    max-width: 50vw;
    border-radius: 0.3rem;

    .access-modal-title {
      color: $navy;
      text-align: center;
      font-size: 2.2em;
      font-weight: 700;
    }

    .access-modal-body {
      color: $navy5;
      font-size: 16px;
      font-weight: 400;
    }
  }
}