@import "../gaigColors";

#wizardPanel {
  hr {
    margin: 2em -2em 2em -2em;
    border: 1px solid var(--bs-gray-800);

    &:after {
      display: none;
    }
  }

  .gaig-panel {
    margin: auto;
    width: 100%;
  }

  h1.panel-header {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .btn {
    &.enabled {
      background-color: $navy;

      p {
        color: white;
      }
    }

    &.disabled {
      background: $navy8;
      border-radius: 4px;
      color: $navy6;
    }
  }

  .form-tip {
    color: $navy;
    line-height: 16px;
  }

  .input-group > .form-control {
    border-left: none;
  }

  .form-label {
    font-size: .875em;
    margin-bottom: 0;
  }

  .input-group-text {
    background-color: transparent;
    border-right: none;
    padding-right: 2px;
    color: $navy6;
  }

  #nextBtn {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    width: 75%;
    margin-bottom: 1em;
  }

  .progress {
    width: 20%;
    height: 3px;
    background-color: $mariner4;
    position: relative;
    left: 38%;
    top: .75em;
  }

  .progress-bar {
    background-color: $navy3;
  }

  .checkpoints {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    color: $navy8;
    width: 20%;
    position: relative;
    left: 37%;

    > span {
      display: flex;
      flex-direction: column;
      width: 50%;

      .checkpointTitle {
        text-transform: uppercase;
        margin-top: 1em;
        font-size: 11px;
        font-weight: 600;
        width: 9em;
        max-width: 30%;
        text-align: center;
        display: flex;
        // this helps center the text relative to the checkpoint dot
        position: relative;
        left: -1rem;
      }

      &:last-of-type {
        div.circle {
          align-self: end;
          position: relative;
          left: .75em;
        }

        .checkpointTitle {
          align-self: end;
        }
      }

      div.circle {
        width: 16px;
        height: 16px;
        background-color: $mariner4;
        border-radius: 10em;

        &.current {
          border: 3px solid $mariner4;
          background-color: $navy;
        }
      }
    }

  }
}
