@import "../gaigColors";

#insuredSignIn {

  #failedSignInMessage {
    color: #d21300;
    text-align: center;
    padding: 0.5em;
  }

  .inputIcon {
    color: $navy5;
  }

  .insRequestAccessArrow {
    margin-left: .74em;
  }

  a {
    color: $navy;
  }

  #resetPasswordWrapper {
    margin-top: 0.5em;
    #resetPasswordLink {
      text-decoration: underline;
      svg {
        color: white;
        border: 1px solid $navy;
        background-color: $navy;
        border-radius: 50%;
        margin-right: 2%;
      }
    }
  }
}
