@import "../../../assets/sass/gaigColors";

#resetPassword {

  .gaig-panel {
    color: $navy;
  }

  a.ga_Button {
    margin-top: 1em;
    display: inline-block;
    text-align: center;
    width: 100%;
    text-decoration: none;

    &:hover {
      color: white;
    }

    > svg {
      margin-left: 0.5em;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  hr {
    &::after {
      width: unset;
    }
  }

  a.try-again {
    color: $navy;
    text-decoration: underline;
    font-weight: bold;
  }

  a.telephone {
    color: $navy;
    text-decoration: none;
    font-weight: bold;
  }

  .input-group-text {
    background-color: transparent;
    border-right: none;
    padding-right: 0;
    & + input {
      border-left: none;
    }
  }

  .tooltip-footer {
    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    padding-top: 16px;
    color: #00274E;
  }

  .password-text {
    font-size: 11px;
    padding-top: 16px;
  }

  .form-text.error-text {
    color: $failure;
  }

  .form-tip {
    &:not(.error-text) {
      color: $navy;
    }
  }
}

