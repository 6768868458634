footer#siteFooter {
  font-size: 12px;
  line-height: 130%;
  width: 40%;
  margin-top: 32px;

  p {
    margin-bottom: 16px;
    color: white;

    a {
      color: white
    }
  }
}