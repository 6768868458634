@import url("https://use.typekit.net/nyu5uat.css"); /* custom gaig fonts */
@import url("./gaigColors.css");
/* html, body, #root, #root > div {
  height: 100%;
} */

body {
  margin: 0 !important;
  font-family: proxima-nova, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--navy) !important;
  background-image: url("../images/login-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* border: 3px solid green; */
  height: 100vh;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: jaf-bernina-sans-comp, sans-serif
}

/* ------------ Login Container ---------------- */

.ga_login-app-container {
  /* border: 3px solid rgb(204, 48, 157); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 16px 32px;
}

/* ------------ Login Header ---------------- */

.ga_login-app-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ga_login-app-header-container-image {
  width: 150px;
  height: 69px;
}

.ga_login-app-header-container-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ga_login-app-header-container-support {
  height: fit-content;
  display: flex;
}

.ga_login-app-header-container-support-info {
  display: flex;
  height: fit-content;
}

.ga_login-app-header-container-support-info > p {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  color: #ffffff;
  margin: 0;
}

.ga_login-app-header-container-support-info-callIcon {
  color: white;
  margin-right: 4px;
  margin-left: 12px;
}

/* ------------ Login Body ---------------- */

.ga_login-app-middle-wrapper {
  display: flex;
  /* border: 1px solid red; */
}

.ga_login-app-middle-wrapper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1em;
  /* border: 1px solid rgb(91, 187, 35); */
}

.ga_login-app-middle-wrapper-container-loginPanel {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 51, 102, 0.2);
  border-radius: 12px;
  width: 384px;
  height: fit-content;
}

.ga_login-app-middle-wrapper-container-signPanel {
  background: #ccd4dc;
  box-shadow: 0px 4px 20px rgba(0, 51, 102, 0.2);
  border-radius: 0px 12px 12px 0px;
  width: 347px;
  height: 359px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
}

.ga_loginPanelTile-container {
  padding: 32px 32px 16px 32px;
}

.ga_loginPanelTile-container-title h1 {
  font-family: "jaf-bernina-sans-comp", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--navy);
  margin-bottom: 0;
}

.ga_loginInput-wrapper {
  /* border: 1px solid red; */
  padding: 0px 32px 0px 32px;
}
.ga_loginInput-wrapper-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ga_loginInput-wrapper label {
  font-family: "Proxima-nova", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 125%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #677d95;
}

.ga_loginInput-wrapper span {
  color: var(--failure);
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.ga_loginInput-wrapper-inputWrapper {
  width: 100%;
  border: 2px solid #ccd4dc;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.ga_loginInput-wrapper-inputWrapper input {
  width: 100%;
  border-radius: 4px;
  border: none;
  margin-left: 8px;
  padding: 8px 16px 8px 8px;
  height: 100%;
}
.ga_loginInput-wrapper-inputWrapper input::placeholder {
  font-family: "Proxima-nova", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  color: #99a9b8;
}

.ga_loginInput-wrapper-forgot {
  display: flex;
  align-items: center;
}
.ga_loginInput-wrapper-forgot a {
  font-family: "Proxima-nova", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  text-decoration-line: underline;
  color: var(--navy);
  margin-left: 4px !important;
}
.ga_loginInput-wrapper-forgot-username {
  display: flex;
  align-items: center;
  padding: 3px 5px;
}
.ga_loginInput-wrapper-forgot-username a {
  font-family: "Proxima-nova", Arial, sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 15.6px;
  text-decoration: none;
  color: var(--navy);
  margin-left: 4px !important;
  font-weight: bold;
}

.ga_loginPanel-bottom {
  border-top: 2px solid #ccd4dc;
  padding: 24px 32px 32px 32px;
}
.ga_loginPanel-bottom .button-wrapper {
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ga_agrement-wrapper {
  width: 80%;
  margin: 0 auto;
}

.ga_access-text {
  text-align: center;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--navy);
  margin-top: 10px;
  margin-bottom: 0px;
}

.ga_signup-text {
  padding: 32px 32px 0 32px;
}

.ga_signup-text-header {
  font-family: "jaf-bernina-sans-comp", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: var(--navy);
}

.ga_signup-text-description {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--navy);
  padding-bottom: 0 !important;
}

.ga_Signup-AccessRequest {
  display: flex;
  align-items: center;
  margin: 0 32px;
}

.ga_Signup-AccessRequest .ga_Signup-AccessRequest-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 32px;
  border: 2px solid var(--navy);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--navy);
  text-decoration: none;
}

body b {
  font-size: 14px;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal .unqk-modal__modal-header .unqk-modal__close-button {
  height: 20px;
  width: 20px;
}

input {
  font-family: Font awesome\5 Pro, "Open Sans", proxima-nova, sans-serif !important;
}

.gaig-number {
  float: right;
}

.auth-content {
  margin: auto;
  background-color: red;
}

.col-sm-6 {
  padding-left: 0px;
  padding-right: 0px;
}

.link-margin {
  margin-top: -15px;
}

.link-underline {
  text-decoration: underline;
}

.form-field-type-textfield {
  margin-bottom: 0px !important;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0px;
}

#gaig-header {
  margin-top: 50px;
  position: fixed;
  width: 95vw;
  left: 3%;
  top: 0%;
}

.margin-top-workflow {
  margin-top: 25vh;
}

.agentSignIn {
  font-family: jaf-bernina-sans-comp, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 110%;
  color: var(--navy);
  text-transform: none;
  margin-top: 30px;
  margin-bottom: 20px;
}

hr {
  border-top: none;
}

hr:after {
  background-color: var(--navy8);
  content: "";
  height: 1px;
  position: absolute;
  transform: translate(-7.5%, 0%);
}

.group-control-label,
label {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 3px;
  line-height: 125%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--navy5);
}

.form-field-type-password .form-control {
  font-size: 14px;
}

.login-btn {
  height: 40px;
  width: 218px;
  left: 447px;
  top: 566px;
  padding: 8px 20px 8px 20px;
  border-radius: 4px;
  background: #036;
  border-color: #036;
  color: #fff;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.requestButtonWrapper {
  height: 80%;
}

.btn.btn-secondary.btn-md.btn-block {
  flex-direction: row;
  padding: 9px 20px 8px;
  width: 185px;
  height: 40px;
  left: 808px;
  top: 546px;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  border-color: #ccdff3;
  color: #036;
  border-width: 2px;
  border-radius: 5px;
  font-weight: bold;
  margin: auto;
}

.btn.btn-link.btn-md.btn-block {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--navy4);
}

.access-text {
  text-align: center;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--navy4);
  margin-top: 10px;
  margin-bottom: 0px;
}

a {
  color: var(--link);
}

.passwordSection {
  padding-top: 1em;
  padding-bottom: 3em;
}

.loginHR {
  height: 1.8px !important;
}
.input-group {
  box-shadow: none;
  border-radius: 4px;
}

.button-label {
  margin: auto;
}

.homeButton {
  max-height: 4em;
  display: flex !important;
  align-self: center;
  justify-content: center;
}

/* Box colors */
.bg-1 {
  background: #eceff1;
  color: #37474f;
}
.bg-2 {
  background: var(--lavender);
  color: #eceff1;
}
.bg-3 {
  background: #37474f;
  color: #fff;
}

/* Common button styles */
.button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button:focus {
  outline: none;
}

.button > span {
  vertical-align: middle;
}

/* Text color adjustments (we could stick to the "inherit" but that does not work well in Safari) */
.bg-1 .button {
  color: #37474f;
  border-color: #37474f;
}
.bg-2 .button {
  color: #eceff1;
  border-color: #eceff1;
}
.bg-3 .button {
  color: #fff;
  border-color: #fff;
}

/* Sizes */
.button--size-s {
  font-size: 14px;
}
.button--size-m {
  font-size: 16px;
}
.button--size-l {
  font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.button--text-thin {
  font-weight: 300;
}
.button--text-medium {
  font-weight: 500;
}
.button--text-thick {
  font-weight: 600;
}
.button--round-s {
  border-radius: 5px;
}
.button--round-m {
  border-radius: 15px;
}
.button--round-l {
  border-radius: 40px;
}

/* Borders */
.button--border-thin {
  border: 1px solid;
}
.button--border-medium {
  border: 2px solid;
}
.button--border-thick {
  border: 3px solid;
}

/* Individual button styles */

/* Ujarak home buttons */
.button--ujarak {
  color: white !important;
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
}
.button--ujarak::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1c57ac;
  z-index: -1;
  opacity: 0;
  -webkit-transform: scale3d(0.7, 1, 1);
  transform: scale3d(0.7, 1, 1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak.button--round-s::before {
  border-radius: 2px;
}
.button--ujarak.button--inverted::before {
  background: var(--lavender);
}
.button--ujarak,
.button--ujarak::before {
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak:hover {
  color: #fff;
  border-color: #37474f;
}
.button--ujarak.button--inverted:hover {
  color: #37474f;
  border-color: #fff;
}
.button--ujarak:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.close {
  border-width: 0;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background-color: transparent;
  font-size: 2em;
}

.close:hover {
  opacity: 0.8;
}

.error-text {
  color: var(--failure);
}

.spinner {
  animation: 1s linear infinite spin;
}

@keyframes spin {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
