@import "../gaigColors";

#insuredRegistrationPage {

  .insuredFooter {
    color: $link;
    background: transparent;
    padding: .5em 1.5em .5em 1.5em;
    border-color: $navy8;
    border-width: 2px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;

    &:hover {
      background: $navy5;
    }
  }

  #footerButtonWrapper {
    display: flex;
    justify-content: center;
    #footerButton {
      color: $navy;
      font-weight: bold;
      text-decoration: none;
      width: 75%;
      border-color: $navy7;
      text-align: center;
      &:hover {
        color: white;
        border-color: $navy;
      }
    }
  }

  b.required {
    color: $failure;
  }

  span.access-info {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
  }

  a.email-link {
    color: unset;
    font-weight: bold;
  }

  button.btn.btn-danger {
    background: transparent;
    color: $failure;
    margin-top: 1em;
  }

  .form-control.error { border: 1px solid $red; }

  .hidden {
    display: none;
  }

  .error-text {
    color: $red;
  }

  .primary-office {
    border: 0px;
    background-color: #ffffff;
    text-decoration: underline;
    color: #004E9C;
  }

  .primary-office-btn {
    cursor: pointer;
  }

  .primary-office-text {
    color: #00274E;
    font-family: "Bernina Sans", sans-serif;
    font-stretch: condensed;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
  }

  .address-text {
    color: #00274E;
    font-family: "Proxima-nova", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .cancel-btn {
    background-color: transparent;
    border: solid #00274E;
    color: #00274E;
    text-align: center;
    text-decoration: none;
  }

  button:disabled {
    background-color: #E6E9ED;
    color: #99A9B8;
    border: none;
  }

  select:invalid {
    color: grey;
  }

  .account-info-label {
    padding-top: 16px;
  }

  .form-tip {
    i {
      margin-right: .25em;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .title {
    text-align: center;
    margin-bottom: 0.5em;
  }

  .value {
    font-weight: bold;
  }

  #invalidPolicyCombo > div {
    border: 1px solid $failure;
    margin-top: 2em;
    padding: 1em;
    border-radius: 6px;
    text-align: center;
    color: $failure;
  }

  .error-form {
    .form-tip {
      color: $failure;
      svg, path {
        stroke: $failure;
        fill: $failure;
      }
    }
    .input-group * {
      border-color: $failure;
    }
  }
}

