:root {
    --navy: #00274E;
    --red: #ED0139;
    --mariner: #004E9C;
    --lavender: #7F7ED6;
    --burgundy: #692340;
    --sand: #AAA276;
    --gold: #FFB400;
    --shamrock: #00A85B;
    --whiteHover: rgb(255, 255, 255, 0.1);
    --navy1: #00172F;
    --navy2: #001F3E;
    --navy3: var(--navy);
    --navy4: #335271;
    --navy5: #677D95;
    --navy6: #99A9B8;
    --navy7: #CCD4DC;
    --navy8: #E6E9ED;
    --navy9: #F2F4F6;
    --navy10: #FAFAFB;
    --red1: #8E0122;
    --red2: #BE012E;
    --red3: var(--red);
    --mariner1: #1D3A85;
    --mariner2: var(--mariner);
    --mariner3: #83A0EB;
    --mariner4: #D6DFF8;
    --lavender1: #4C4C80;
    --lavender2: var(--lavender);
    --lavender3: #B2B2E6;
    --lavender4: #E5E5F7;
    --burgundy1: #3F1526;
    --burgundy2: var(--burgundy);
    --burgundy3: #A57B8C;
    --burgundy4: #E1D3D9;
    --sand1: #666147;
    --sand2: var(--sand);
    --sand3: #CCC7AD;
    --sand4: #EEECE4;
    --gold1: #996C00;
    --gold2: var(--gold);
    --gold3: #FFD266;
    --gold4: #FFF0CC;
    --shamrock1: #006738;
    --shamrock2: var(--shamrock);
    --shamrock3: #66CD9E;
    --shamrock4: #CCEEDF;
    --link: var(--mariner);
    --success: var(--shamrock);
    --warning: var(--gold);
    --failure: var(--red);
}
