@import "../gaigColors";

#sessionRedirectModal {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 50%;
  margin-top: 10%;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
