@import "../gaigColors";

#agentSignIn {

  .inputIcon {
    color: $navy5;
  }
  .agentRequestAccessArrow {
    margin-left: .75em;
  }

}